import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import SignUpForm from "./style"
import { Images } from "~data"
import { Link } from "~components"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { navigate } from "gatsby"

const schema = yup
  .object({
    username: yup.string().required(),
    email: yup
      .string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password length should be at least 6 characters"),
    passwordConfirm: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password")], "Passwords must and should match"),
    acceptTerms: yup.bool().oneOf([true], "Privacy policy is required"),
  })
  .required()

export default function SignUp() {
  const [errorRequest, setErrorRequest] = useState(null)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const onSubmit = data => {
    console.log(data)

    // TODO handling sign up service
    // use setErrorRequest if an error exists
  }

  return (
    <SignUpForm backgroundColor="#f3f4f6">
      <Container className="position-static">
        <Row className="align-items-center justify-content-center position-static">
          <Col xs="12" className="col-xl-6 col-lg-5 position-static">
            <SignUpForm.Image
              backgroundImage={Images.Account.Signup}
            ></SignUpForm.Image>
          </Col>
          <Col xs="12" className="col-xxl-6 col-xl-6 col-lg-7 col-md-9">
            <SignUpForm.Box plXXL="60px">
              <SignUpForm.Title as="h2">
                <SignUpForm.IconButton to="/account/sign-in">
                  <i className="fas fa-arrow-left" />
                </SignUpForm.IconButton>
                Regístrate en Enefevo
              </SignUpForm.Title>
              <SignUpForm.Text as="p">
                When, while lovely valley teems with vapour around atlas meand
                meridian the upper impenetrable.
              </SignUpForm.Text>
              {errorRequest && <p className="error-message">{errorRequest}</p>}
              <SignUpForm.FromSection>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-floating">
                    <label htmlFor="name">Your Name</label>
                    <input
                      className="form-control"
                      placeholder="Your Name"
                      {...register("username", { required: true })}
                    />
                    <p className="error-message">{errors.username?.message}</p>
                  </div>
                  <div className="form-floating">
                    <label htmlFor="email">Your Email</label>
                    <input
                      className="form-control"
                      placeholder="Your Email"
                      type="email"
                      {...register("email", { required: true })}
                    />
                    <p className="error-message">{errors.email?.message}</p>
                  </div>
                  <div className="form-floating">
                    <label htmlFor="password">Contraseña</label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Cotraseña"
                      {...register("password", { required: true })}
                    />
                    <p className="error-message">{errors.password?.message}</p>
                  </div>
                  <div className="form-floating">
                    <label htmlFor="passwordConfirm">
                      Confirm Your Password
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Confirm Your Password"
                      {...register("passwordConfirm", { required: true })}
                    />
                    <p className="error-message">
                      {errors.passwordConfirm?.message}
                    </p>
                  </div>
                  <div className="form-check d-flex align-items-center mt-6 mb-3">
                    <input
                      className="form-check-input bg-white float-none mt-0 mb-0 me-3"
                      type="checkbox"
                      {...register("acceptTerms", { required: true })}
                    />
                    <SignUpForm.FormText>
                      I agree to all the statements included in
                      <Link
                        className="btn-link text-electric-violet-2 ms-2"
                        to="/privarcy"
                      >
                        privacy policy
                      </Link>
                    </SignUpForm.FormText>
                    <p className="error-message">
                      {errors.acceptTerms?.message}
                    </p>
                  </div>
                  <SignUpForm.FormButton className="btn-primary mt-2">
                    Registrar
                  </SignUpForm.FormButton>
                </form>
              </SignUpForm.FromSection>
            </SignUpForm.Box>
          </Col>
        </Row>
      </Container>
    </SignUpForm>
  )
}
